<template>
  <div class="ask-left">
    <div class="side-bar">
      <div class="header-icon">
        <svg t="1723864593676" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
             p-id="39579" width="48" height="48">
          <path
              d="M620.032 5.632H403.968c-135.68 0-245.76 110.08-245.76 245.76v215.552c0 135.68 110.08 245.76 245.76 245.76h186.88l113.664 63.488c18.432 10.24 40.96-3.072 40.96-23.552v-75.264c71.68-43.008 119.296-120.832 119.296-210.432V250.88c0.512-135.168-109.568-245.248-244.736-245.248z"
              fill="#366AFF" p-id="39580"></path>
          <path
              d="M268.288 865.792l20.48 153.088h-46.592l-5.632-41.472H172.544l-17.92 41.472h-44.032l56.32-136.704c4.096-9.728 13.312-15.872 23.552-15.872h77.824z m-59.392 25.6l-26.112 59.392h51.712l-7.68-59.392h-17.92zM391.68 992.768l2.56-17.92H316.416l7.168-48.128c2.048-12.288 12.8-22.016 25.088-22.016h102.4l-5.12 33.28h-45.056l1.024-7.68h-31.232l-2.56 17.92h75.264l-5.632 51.712c-1.024 5.12-4.096 9.728-8.704 12.8-4.608 3.584-9.216 5.12-14.848 5.12H311.296l5.12-26.112 75.264 1.024zM547.84 849.92l-10.24 75.264 33.792-23.04h56.832l-72.704 49.152 56.832 67.584h-56.832l-26.112-33.792-5.12 33.792h-51.2l20.992-146.432c2.048-12.8 12.8-22.016 25.088-22.016h28.672zM795.648 865.792l20.48 153.088h-46.592l-5.632-41.472h-65.024L680.96 1018.88h-44.032l55.808-136.704c4.096-9.728 13.312-15.872 23.552-15.872h79.36z m-59.904 25.6l-26.112 59.392h51.712l-7.68-59.392h-17.92zM913.92 865.792l-23.552 153.088h-49.152l16.896-130.56c1.536-12.8 12.288-22.528 25.6-22.528h30.208z"
              fill="#386CFF" p-id="39581"></path>
          <path
              d="M531.968 522.752c-13.824 0-26.624-9.216-30.72-23.04L460.8 355.84c-1.536-6.144-10.24-6.144-12.288-0.512L413.184 455.68c-0.512 2.048 1.024 4.096 3.072 4.096H424.96c15.872 0 30.208 10.752 32.768 26.112 3.584 19.968-11.776 37.376-31.232 37.376H367.616c-10.24 0-19.968-5.12-25.6-13.312-6.144-8.192-7.68-18.944-4.096-28.672l90.624-261.12c4.608-13.312 16.896-21.504 30.72-21.504 13.824 0.512 25.6 9.728 29.696 23.04l73.728 261.12c4.608 16.896-5.12 34.304-22.016 38.912-3.072 1.024-6.144 1.024-8.704 1.024z"
              fill="#FFFFFF" p-id="39582"></path>
          <path
              d="M656.384 525.312c-13.824 0-26.624-9.216-30.72-23.04l-74.752-263.168c-4.608-16.896 5.12-34.304 22.016-39.424 16.896-4.608 34.304 5.12 39.424 22.016l74.752 263.168c4.608 16.896-5.12 34.304-22.016 39.424-2.56 0.512-5.632 1.024-8.704 1.024z"
              fill="#FFFFFF" p-id="39583"></path>
        </svg>
      </div>
      <div
           v-for="(item,index) in preNavList"
           :key="index"
           :class="[activeNavId === item.id ? 'active-side-item' : '',item.id !== 'invite' ? 'side-item' : 'invite-item']"
           @click="handleSelect(item)"
      >
        <template v-if="item.id !== 'setup'">
          <i :class="item.icon" v-if="item.id !== 'invite'"></i>
          <span>{{ item.name }}</span>
        </template>
        <el-popover
            placement="right-start"
            width="120"
            trigger="hover">
          <div class="set-up-container">
            <div class="set-item"
                 v-for="items in item.children"
                 :key="items.id"
                 @click="handleSelect(items)"
            >
              <i :class="items.icon"></i>
              <span>{{ items.name }}</span>
            </div>
          </div>
         <span slot="reference" class="set-up" v-if="item.id === 'setup'">
           <i :class="item.icon"></i>
           <span>{{ item.name }}</span>
         </span>
        </el-popover>
      </div>
      <el-popover
        placement="right-start"
        v-if="otherNavList.length > 0"
        v-model="otherNavListVisible"
        trigger="manual">
        <div class="other-nav">
          <div class="other-nav-item"
               v-for="items in otherNavList"
               :key="items.id"
               @click="handleSelect(items)"
          >
            <i :class="items.icon"></i>
            <span>{{ items.name }}</span>
          </div>
        </div>
        <div class="side-item" @click="otherNavListVisible = !otherNavListVisible" slot="reference">
          <i class="iconfont guoran-qita"></i>
          <span>其他</span>
        </div>
      </el-popover>
    </div>
    <div class="bottom-bar">
      <div class="aph-web-user-info">
        <el-dropdown placement="top-start" @command="handleCommand" trigger="click" @visible-change="visibleChange" ref="refSelect" :global="true">
          <!--              <span class="aph-web-user-info-simple-name" v-if="accountSource != 11 && accountSource != 12">个人<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i></span>-->
          <!--              <span class="aph-web-user-info-simple-name" v-else>-->
          <!--                <el-tooltip  v-if="accountSourceName.length > 6" class="item" effect="dark" :content="accountSourceName" placement="top-end">-->
          <!--                  <span>{{ accountSourceName.slice(0,6) + '...'}}</span>-->
          <!--                </el-tooltip>-->
          <!--                <span v-else>{{ accountSourceName}}</span>-->
          <!--                <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
          <!--              </span>-->
          <div class="switch" v-if="!isClient && !isLishi">
            <i class="iconfont guoran-tongyichicun-qiehuan"></i>
            <span>切换企业</span>
          </div>
          <el-dropdown-menu slot="dropdown" class="app-header-company-list">
            <el-dropdown-item
                :command="item.mainId"
                v-for="(item,index) in hadJoinCompanyList"
                :key="index"
                :class="mainId === item.mainId ? 'active-company' : ''"
            >{{item.name}}</el-dropdown-item>
            <el-dropdown-item class="create-company-button" command="create" divided>新建/加入企业</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="aph-web-user-avt">
        <el-popover v-model="popoverVisible" placement="right-start" width="220" trigger="click"
                    popper-class="aph-web-user-avt-pop">
          <div class="awua-box">
            <div class="awuab-top">
              <div class="awuab-top-avt">
                <el-upload class="avatar-uploader" :http-request="((data) => {
                  return aliyunOssRequest(data, 'ask')
                })" :show-file-list="false" :on-success="handleAvatarSuccess"
                           :before-upload="beforeAvatarUpload" :auto-upload="true">
                  <div class="awuab-top-avt-default">
                    <img v-if="avtImageUrl" height="31px" width="31px" style="border-radius: 50%;"
                         :src="avtImageUrl" class="awuab-top-avt-default-name-image">
                    <span v-else-if="userInfo.name" class="awuab-top-avt-default-name">{{
                        userInfo.name.substring(userInfo.name.length - 2, userInfo.name.length)
                      }}</span>
                    <span class="awuab-top-avt-default-hover iconfont guoran-xiangji1"></span>
                  </div>
                </el-upload>
              </div>
              <div class="awuab-top-name">
                <span v-show="!nameEditing" class="awuab-top-name-text">{{
                    userInfo.name }}
                </span>
                <div v-show="nameEditing" class="awuab-top-name-edit">
                  <el-input ref="nameEditing" @focus="nameEditingFocus" size="small"
                            v-model="userInfoEditName"
                            @keyup.enter.native="saveCustomerInfo('realName', userInfoEditName)"
                            maxLength="20" placeholder="请输入用户名"></el-input>
                  <span @click="saveCustomerInfo('realName', userInfoEditName)"
                        class="atne-save iconfont guoran-icon_duihao-mian2"></span>
                  <span @click="nameEditing = false"
                        class="atne-delete iconfont guoran-cuohao2"></span>
                </div>
              </div>
            </div>
            <div v-if="!isLishi" class="ask-lw-combination">
              <div class="alc-item">
                <div class="alc-item-title">
                  <span class="alc-item-title-pre">
                    <span class="aitp-name">智能问答与写作</span>
                    <el-tooltip class="item" effect="dark" content="使用AI能力生成文案以及答案" placement="top-end">
                      <span class="iconfont guoran-tongyichicun-yiguoqi"></span>
                    </el-tooltip>
                  </span>
                  <span @click="openPayVersion" class="alc-item-title-after" v-if="currentVersion.vipType != 3">升级</span>
                </div>
                <div class="alc-item-value">
                  <span class="alc-item-value-pre">
                    <span :style="{width: combinationInfo.flowPercentage + '%'}" class="alc-item-value-pre-innner"></span>
                  </span>
                  <span class="alc-item-value-after">
                    <span class="aiva-user">{{ combinationInfo.flowUsedText }}/</span>
                    <span class="aiva-all">{{ combinationInfo.flowTotalText }}次</span>
                    <!-- 0：免费版，1：超级会员，2：高级会员 3 企业版 -->
                    <span v-if="currentVersion.vipType === 3">
                      <span v-if="combinationInfo.companyFlowMonthRefresh">/月</span>
                    </span>
                    <span v-else-if="(currentVersion.vipType === 1 || currentVersion.vipType === 2) && !currentVersion.expire">/月</span>
                  </span>
                </div>
                <div class="alc-item-red-tips" v-show="combinationInfo.flowPercentage >= 80 && combinationInfo.flowPercentage < 100">次数即将用尽</div>
                <div class="alc-item-red-tips" v-show="combinationInfo.flowPercentage >= 100">次数已用尽</div>
              </div>
              <div class="alc-item">
                <div class="alc-item-title">
                  <span class="alc-item-title-pre">
                    <span class="aitp-name">次数包</span>
                    <el-tooltip class="item" effect="dark" content="每次购买的次数包有限期为365天" placement="top-end">
                      <span class="iconfont guoran-tongyichicun-yiguoqi"></span>
                    </el-tooltip>
                  </span>
                </div>
                <div class="alc-item-value">
                  <div class="alc-item-value-surplus-times">
                    <span class="surplus-times">剩余<span class="times">{{combinationInfo.flowPackageTotal}}次</span></span>
                    <span @click="openPayVersion" class="alc-item-title-after" v-if="currentVersion.vipType != 3">购买次数</span>
                  </div>
                </div>
                <div class="alc-item-red-tips" v-if="combinationInfo.flowPackageTotal > 0 && combinationInfo.earliestExpireFlowCount > 0 && combinationInfo.withinAmonth">{{combinationInfo.earliestExpireFlowCount}}次将于{{combinationInfo.earliestExpireTime }}到期</div>
              </div>
              <div class="alc-item">
                <div class="alc-item-title">
                  <span class="alc-item-title-pre">
                    <span class="aitp-name">容量</span>
                  </span>
                  <span @click="openPayVersion" class="alc-item-title-after" v-if="currentVersion.vipType != 3">升级</span>
                </div>
                <div class="alc-item-value">
                  <span class="alc-item-value-pre">
                    <span :style="{width: combinationInfo.capacityPercentage + '%'}" class="alc-item-value-pre-innner"></span>
                  </span>
                  <span class="alc-item-value-after">
                    <span class="aiva-user">{{handlerUnit(combinationInfo.capacityUsed + combinationInfo.companyCapacityUsed)}}/</span>
                    <span class="aiva-all" >{{handlerUnit(combinationInfo.capacityTotal)}}</span>
                  </span>
                </div>
                <div class="alc-item-red-tips" v-show="combinationInfo.capacityPercentage >= 90 && combinationInfo.capacityPercentage < 100">容量即将用尽</div>
                <div class="alc-item-red-tips" v-show=" combinationInfo.capacityPercentage >= 100">容量已用尽</div>
              </div>
              <div class="alc-item company-tips" v-if="currentVersion.vipType == 3" >
                次数和容量不够用？<span @click="goBuy">点我</span>
              </div>
            </div>
            <div v-if="!isLishi" class="awuab-handle-box">
              <div @click="toxiaochao" class="ahb-cell" v-if="!isClient">
                <span class="ahb-cell-icon iconfont guoran-tongyichicun-piliangbianjiiocn"></span>
                <span class="ahb-cell-text">意见反馈</span>
              </div>
              <div @click="toRedemptionCode" class="ahb-cell" v-if="!isClient && (accountSource != 11 && accountSource != 12)">
                <span class="ahb-cell-icon iconfont guoran-duihuanma"></span>
                <span class="ahb-cell-text">兑换码</span>
              </div>
              <div @click="logout" class="ahb-cell" v-if="!isClient">
                <span class="ahb-cell-icon iconfont guoran-tuichu1"></span>
                <span class="ahb-cell-text">退出登录</span>
              </div>
            </div>
          </div>
          <div v-if="isLishi" class="awua-box">
            <div class="awuab-handle-box">
              <div @click="logout" class="ahb-cell">
                <span class="ahb-cell-icon iconfont guoran-tuichu1"></span>
                <span class="ahb-cell-text">{{$t('leftNav.logOut')}}</span>
              </div>
            </div>
          </div>
          <div class="avatar" slot="reference">
            <img height="31px" width="31px" style="border-radius: 50%;margin-top: 5px;"
                 v-if="avtImageUrl != ''" :src="avtImageUrl">
            <span v-else-if="userInfo.name" class="aph-web-user-avt-inner">{{
                userInfo.name.substring(userInfo.name.length - 2, userInfo.name.length) }}</span>
          </div>
        </el-popover>
      </div>
    </div>
    <inviteDialog :showInviteDialog="showInviteDialog"  @close="showInviteDialog = false" />
    <beyondLimit
        :showMorePopups.sync="showMorePopups"
        :showApplyInfoPopups.sync="showApplyInfoPopups"
        :exceedObj="exceedObj"
        :currentVersion="currentVersion">
    </beyondLimit>
  </div>
</template>

<script>
import { requestUrl } from "@/http/requestUrl";
import { mapActions, mapGetters } from "vuex";
import { redirectUri } from "@/http/redirectUri";
import InviteDialog from "@/components/inviteUsers/inviteDialog";
import BeyondLimit from "@/views/testComponents/beyondLimit";
import { initSsoUid } from "@/http/cookie";
import { multipartUpload, ossFileUrl } from "@/views/testComponents/AliyunIssUtil";
import { generateOssConfig } from '@/assets/js/ossConfig';

export default {
  name: "newMainNav",
  data(){
    return{
      isLishi: false,

      navList: [],
      activeNavId:"bot",
      knowledgeUserName:"",
      showInviteDialog:false,
      showMorePopups:false,
      showApplyInfoPopups:false,
      exceedObj:null,
      accountSource:"",
      accountSourceName:"",
      avtImageUrl:"",
      hadJoinCompanyList:[],
      onlyShowUserIcon:false, // 是否仅展示用户头像
      userInfo: {
        name: "",
      },
      popoverVisible:false,
      nameEditing: false,
      userInfoEditName: "",
      isClient:false,
      mainId:localStorage.getItem('_mainId'),

      preNavList: [],
      otherNavList: [],
      otherNavListVisible: false,
    }
  },
  components:{
    InviteDialog,
    BeyondLimit
  },
  props: {
    // 套餐信息 智能写作/容量
    combinationInfo: {
      type: Object,
      default: () => {
        return {
          capacityUsed: 0,
          capacityTotal: 1,
          capacitySurplus: 1,
          flowUsed: 0,
          flowTotal: 100,
          flowSurplus: 100
        }
      }
    },
  },
  computed:{
    ...mapGetters('user', {
      currentVersion: 'getCurrentVersion'
    }),
  },
  beforeDestroy() {
    // 移除事件监听器，避免内存泄漏
    window.removeEventListener('resize', this.updateWindowHeight);
  },
  methods:{
    // 顶部触发添加知识 修改状态管理 apphandle 模块
    ...mapActions('apphandle', [
      'updateIsTopAddDoc'
    ]),
    ...mapActions('user', [
      "updateLastClickLNavTamp"
    ]),
    ...mapActions('apphandle', [
      'updateIsSwitchCompany'
    ]),
    ...mapActions('user', [
      'updateCombinationInfo'
    ]),
    ...mapActions('user', [
      'updateCurrentVersionInfo'
    ]),
    updateWindowHeight() {
      // 侧边栏可使用高度
      let navBoxHeight = window.innerHeight - 200 - 64;
      // 计算侧边栏显示导航数量
      let showNavItemNum = Math.floor(navBoxHeight/56);

      this.preNavList = this.navList.slice(0, showNavItemNum);
      this.otherNavList = this.navList.slice(showNavItemNum);
      if (this.otherNavList.length < 2 && this.otherNavList.length > 0) {
        this.preNavList = this.navList;
        this.otherNavList = [];
      }

      if (
        sessionStorage.getItem('isLishi') == 1
      ) {
        this.preNavList.length = 1;
        this.otherNavList = [];
      }
    },
    /**
     * 点击导航，调用父组件相应方法
     * @method handleSelect
     * @param {object} item 导航每项数据
     * @return 点击导航，调用父组件相应方法
     */
    handleSelect(item) {
      this.otherNavListVisible = false;
      // this.$emit("closeTip");
      // this.updateSearchDocModeId("");
      this.activeModeNavId = "";
      if (item.id !== 'managementBackend' && item.id !== 'setup' && item.id !== 'invite' && item.id !== 'staffDirectoryView'){
        this.activeNavId = item.id;
      }
      // if (item.id === 'mode'){
      //   this.activeNavId = 'setup'
      //   this.activeModeNavId = "modeConfig";
      // }

      switch (item.id) {
        case "knowledge":
          this.updateIsTopAddDoc({
            status: false,
            fromRouteName: ""
          });
          this.searchDocModeIdBgActive = "";
          this.$router.push({ name: "knowledgeView", query: { myKnowledgeTo:true } })
          this.updateLastClickLNavTamp(new Date().getTime());
          break;
        case "bot":
          this.$router.push({ name:'searchKnowledge' })
          break;
        case "recycleBin":
          this.updateIsTopAddDoc({
            status: false,
            fromRouteName: ""
          });
          this.$router.push({ name: "recycleBinView", query: "" })
          break;
        case "enterpriseKnowledgeView":
          this.updateIsTopAddDoc({
            status: false,
            fromRouteName: ""
          });
          // this.$router.push({ name: "enterpriseKnowledgeView", query: "" })
          this.$router.push({ name: "knowledgeView", query: { enterpriseKnowledgeTo:true } })
          break;
        case "managementBackend":
          this.goManage('');
          break;
        case "toDo":
          this.$router.push({ name: "toDoList", query: "" })
          break;
        case "order":
          this.$router.push({ name:"workOrderView" });
          break;
        case "invite":
          this.showInviteDialog = true;
          break;
        case "staffDirectoryView":
          // window.open("https://portal.test.askbot.cn/#/auth?token=" + localStorage.getItem("_token"), "_blank")
          // this.$router.push({ name:"staffDirectoryView" });
          this.goManage('_staffManage');
          break;
        case "mode":
          this.$router.push({ name: "TemplateView", query: "" });
          break;
        default:
          break;
      }
    },
    showSelectPlugin(wechatBot){
      let flag = 0;
      if (wechatBot && wechatBot.bindBot && wechatBot.bindBot.botInfos) {
        if (wechatBot.bindBot.botInfos.length > 0) {
          wechatBot.bindBot.botInfos.forEach(element => {
            if (element.botType == 10) {
              flag = 1;
            }
          });
        }
      }
      sessionStorage.setItem('isShowSelectPlugin', flag);
    },
    // 更新菜单列表
    updateNavList(){
      let accountSource = localStorage.getItem('accountSource')
      console.log(accountSource,'accountSource-获取');
      this.knowledgeUserName = localStorage.getItem('knowledgeUserName');
      // 9 个人 ; 其他 : 企业
      this.navList = [
        { name: this.$t('leftNav.agent'), id:'bot', icon:"iconfont guoran-jiqirentouxiang", visible: true, route: '' },
        { name:"我的知识", id:'knowledge', icon:"iconfont guoran-zhishi", visible: false, route: '' },
        { name:"工单", id:'order', icon:"iconfont guoran-tongyichicun-gongdanzhuangtai", visible: false, route: '' },
        { name: "待办", id: 'toDo', icon: "iconfont guoran-daiban", children: [], visible: false, route: '' },
        { name:"模板管理",id:'mode',icon:"iconfont guoran-tongyichicun-14-05-mobanshuxing",visible: false, children: [ { name:"模板管理" ,id:"mode",icon:"iconfont guoran-tongyichicun-14-05-mobanshuxing"}],},
        { name: "回收站", id: "recycleBin", icon: "iconfont guoran-huishouzhan", children: [], visible: true, route: '' },
      ]
      // let haveWorkOrderPermission = sessionStorage.getItem('haveWorkOrderPermission');
      // if (haveWorkOrderPermission){
      //   this.navList = [
      //     { name:"智能体", id:'bot', icon:"iconfont guoran-jiqirentouxiang", visible: true, route: '' },
      //     { name:"我的知识", id:'knowledge', icon:"iconfont guoran-zhishi", visible: false, route: '' },
      //     { name:"工单", id:'order', icon:"iconfont guoran-tongyichicun-gongdanzhuangtai", visible: false, route: '' },
      //     { name: "待办", id: 'toDo', icon: "iconfont guoran-daiban", children: [], visible: false, route: '' },
      //     { name:"通讯录",id:"staffDirectoryView",icon:"iconfont guoran-tongxunlu",visible:false },
      //     { name:"邀请同事",id:'invite',icon:"iconfont guoran-yaoqing",visible: false, },
      //     { name:"模板管理",id:'mode',icon:"iconfont guoran-tongyichicun-14-05-mobanshuxing",visible: false, children: [ { name:"模板管理" ,id:"mode",icon:"iconfont guoran-tongyichicun-14-05-mobanshuxing"}],},
      //     { name: "回收站", id: "recycleBin", icon: "iconfont guoran-huishouzhan", children: [], visible: true, route: '' },
      //   ]
      // }
      if (accountSource == 11 || accountSource == 12){
        this.navList = [
          { name: this.$t('leftNav.agent'), id:'bot', icon:"iconfont guoran-jiqirentouxiang", visible: true, route: '' },
          { name:"我的知识", id:'knowledge', icon:"iconfont guoran-zhishi", visible: false, route: '' },
          { name: "企业知识", id: 'enterpriseKnowledgeView', icon: "iconfont guoran-qiyezhishi", children: [], visible: true, route: '' },
          { name:"工单", id:'order', icon:"iconfont guoran-tongyichicun-gongdanzhuangtai", visible: false, route: '' },
          { name: "待办", id: 'toDo', icon: "iconfont guoran-daiban", children: [], visible: false, route: '' },
          { name:"模板管理",id:'mode',icon:"iconfont guoran-tongyichicun-14-05-mobanshuxing",visible: false, children: [ { name:"模板管理" ,id:"mode",icon:"iconfont guoran-tongyichicun-14-05-mobanshuxing"}],},
          { name: "回收站", id: "recycleBin", icon: "iconfont guoran-huishouzhan", children: [], visible: true, route: '' },
        ]
        if (this.knowledgeUserName && this.knowledgeUserName !== 'null'){
          let staffObj =  { name:"通讯录",id:"staffDirectoryView",icon:"iconfont guoran-tongxunlu",visible:false }
          this.navList.splice(4,0,staffObj)
          let invite = { name:"邀请同事加入企业",id:'invite',icon:"iconfont guoran-yaoqing",visible: false, };
          this.navList.splice(5,0,invite);
          let obj = { name:"管理后台",id:"managementBackend",icon:"iconfont guoran-houtaiguanli",children: [],visible: true,route: '' };
          this.navList.push(obj)
        }
      }

      this.updateWindowHeight();
      window.addEventListener('resize', this.updateWindowHeight);
    },
    //跳转至管理后台
    goManage(link){
      this.$http.post(`${requestUrl.company.getCompanyToken}`,{
        username:localStorage.getItem('realName'),
        tel:localStorage.getItem('phone'),
        mainId:localStorage.getItem('_mainId'),
        profilePhoto: localStorage.getItem('profilePhoto') == 'null' ? '' : localStorage.getItem('profilePhoto'),
      }).then((res) => {
        console.log(res,'token');
        this.popoverVisible = false;
        if(res.data){
          let redirectUrl = '';
          let tempwindow = window.open("_blank");
          // haveManagerPermission用户是否有管理后台操作权限，若该值为true,点击管理后台跳转管理平台，
          // haveKnowledgePermission为null且haveKnowledgePermission=true，跳转知识管理后台
          let haveManagerPermission = localStorage.getItem('haveManagerPermission');
          let haveKnowledgePermission = localStorage.getItem('haveKnowledgePermission');
          if(haveManagerPermission){
            redirectUrl = redirectUri[process.env.VUE_APP_NODE_ENV] ? redirectUri[process.env.VUE_APP_NODE_ENV].callback : redirectUri['development'].callback;
            tempwindow.location = redirectUrl + '#/auth' + '?token=' + res.data + '&isAskLightning=1' + `&link=${link}`;
          } else if(haveManagerPermission == 'null' && haveKnowledgePermission == 'true') {
            redirectUrl = redirectUri[process.env.VUE_APP_NODE_ENV] ? redirectUri[process.env.VUE_APP_NODE_ENV].wisdom : redirectUri['development'].wisdom;
            tempwindow.location = redirectUrl + '/#/?token=' + res.data;
          }
        } else {
          this.messageFn('error', res.message);
        }
      });
      // let redirectUrl = redirectUri[process.env.VUE_APP_NODE_ENV] ? redirectUri[process.env.VUE_APP_NODE_ENV].callback : redirectUri['development'].callback;
      // console.log(redirectUrl,'redirectUrl');
      // let tempwindow = window.open("_blank");
      // tempwindow.location = redirectUrl;

    },

    handleCommand(command){
      console.log(command,'command');
      if(command === 'create'){
        this.$router.push({name:"joinCompany"})
      } else {
        let data = {}
        if(localStorage.getItem('_mainId') !== command){
          let flag = true;
          this.hadJoinCompanyList.forEach(item => {
            if(item.mainId === command){
              console.log(item,'item');
              data = item;
              if(!item.personalAccount){
                flag = item.auditStatus
              }
            }
          })
          if(flag){
            this.updateIsSwitchCompany(true);
            setTimeout(() => {
              this.switchCompany(command,data)
            },500)
          } else {
            this.messageFn('warning', '请等待管理员审核');
          }
        }
      }
    },
    // 在下拉框展开时添加底部固定项(注意该方法可能随版本更新而不适用)
    visibleChange(visible){
      if(visible){
        this.$nextTick(() => {
          let dom = document.querySelector('.app-header-company-list');
          if (!dom){
            return
          }
          dom.style.height = (this.hadJoinCompanyList.length * 36) + 'px';
          if (dom.scrollHeight >= (window.innerHeight - 200)) {
            // console.log("页面有滚动条", Number(dom.style.height.replace('px','')));
            document.querySelector('.el-dropdown-menu__item--divided').style.width = (dom.offsetWidth - 42) + 'px'; // padding : 20*2    border : 1*2
            // 设置top是为什么？
            // document.querySelector('.el-dropdown-menu__item--divided').style.top =  Number( dom.style.top.replace('px',''))  + dom.offsetHeight + 'px';
            // dom.scrollWidth = dom.scrollWidth - 34;
            setTimeout(() =>{
              document.querySelector('.el-dropdown-menu__item--divided').style.bottom = 100 + 'px';
            }, 320)
          } else {
            // console.log("页面没有滚动条");
            document.querySelector('.el-dropdown-menu__item--divided').style.width = (dom.offsetWidth - 42) + 'px';// padding : 20*2    border : 1*2
            // document.querySelector('.el-dropdown-menu__item--divided').style.top =  Number( dom.style.top.replace('px',''))  + Number( dom.style.height.replace('px','')) + 20 + 'px'
            // document.querySelector('.el-dropdown-menu__item--divided').style.bottom = 100 + 'px';
            setTimeout(() =>{
              document.querySelector('.el-dropdown-menu__item--divided').style.bottom = 100 + 'px';
            }, 320)
          }
        })
      }
    },
    // 切换企业
    switchCompany(mainId,datas){
      this.$http.post(`${requestUrl.company.getCompanyToken}`,{
        username:localStorage.getItem('realName'),
        tel:localStorage.getItem('phone'),
        mainId:mainId,
        profilePhoto:localStorage.getItem('profilePhoto') == 'null' ? '': localStorage.getItem('profilePhoto')
      }).then((res) => {
        console.log(res,'res----切换企业');
        if (res.data) {
          if(res.data === "forbidden"){
            this.messageFn('error', '该账号已被禁用!');
            return false;
          }
          let loading = this.$loading({
            lock: true,
            text: '切换中，请稍等...',
            spinner: 'el-icon-loading',
            background: 'hsla(0,0%,100%,.7)',
            customClass:"switch-company-loading"
          });
          // this.updateIsSwitchCompany(false);
          let token = res.data;
          // 切换企业的操作
          this.$http.get(`${requestUrl.company.getToken}?token=${res.data}&setJwtCookie=true`).then((res) => {
            // this.$store.commit("apphandle/setIsSwitchCompany", false);
            console.log("切换企业后的mainId：",res.data.data.mainId)
            localStorage.setItem("_uid", res.data.data.userId);
            sessionStorage.setItem("_uid", res.data.data.userId);
            localStorage.setItem('_mainId', res.data.data.mainId);
            // localStorage.setItem("accountType", res.data.data.accountType);
            localStorage.setItem("roleId", res.data.data.roleId);
            localStorage.setItem("username", res.data.data.username);
            localStorage.setItem("profilePhoto", res.data.data.profilePhoto);
            localStorage.setItem("knowledgeNickName", res.data.data.realName);
            localStorage.setItem("realName", res.data.data.realName);
            sessionStorage.setItem("loginSuccessTimes", res.data.data.loginSuccessTimes);
            localStorage.setItem('rolePrivileges', JSON.stringify(res.data.data.rolePrivileges))
            localStorage.setItem("phone", res.data.data.phone);
            localStorage.setItem("accountSource", res.data.data.source);
            localStorage.setItem("accountSourceName", datas.name);
            localStorage.setItem("_corpId", datas.corpId);
            sessionStorage.setItem('_corpId',datas.corpId);
            localStorage.setItem("knowledgeUserName", res.data.data.knowledgeUserName);
            localStorage.setItem('_token',token)
            sessionStorage.setItem('_token', token)
            sessionStorage.setItem('isFirstGoIndex',1);
            localStorage.setItem('haveKnowledgePermission', res.data.data.haveKnowledgePermission);
            localStorage.setItem('memberInDepartDto', JSON.stringify(res.data.data.memberInDepartDto) || '[]');
            localStorage.setItem('haveManagerPermission', res.data.data.haveManagerPermission);
            localStorage.setItem("authStatus",  res.data.data.authStatus);
            initSsoUid();
            this.mainId = localStorage.getItem('_mainId')
            this.$emit('switchCompany');
            sessionStorage.setItem('_switchCompany',true)
            setTimeout(() => {
              loading.close();
            },2000)
            // this.updateIsSwitchCompany(false);
            this.$router.push({ name:"emptyPage" })
          })
              .catch((error) => {
                console.log(error,'error');
                this.messageFn('error', 'token校验异常,请尝试退出重新登录',2000);
              });
        }
      });
    },
    handlerSwitchCompany(){
      this.userInfo.name = localStorage.getItem("realName");
      this.avtImageUrl = localStorage.getItem("profilePhoto") == "null" ? "https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/2023/03/18/11/48/878f878d-6343-4f48-ada7-86f05211910a/askdefaltleftavt.png" : localStorage.getItem("profilePhoto");
      this.accountSource = Number(localStorage.getItem('accountSource'));
      this.accountSourceName = localStorage.getItem('accountSourceName');
      this.knowledgeUserName = localStorage.getItem('knowledgeUserName');
      // console.log(this.accountSource,this.accountSourceName,'SwitchCompany');
      this.$forceUpdate()
      this.getAllCompany();
    },
    // 获取用户的所有开通知识管理的企业
    getAllCompany(){
      // this.hadJoinCompanyList = [];
      let url = `${requestUrl.company.queryMemberCompany}?`
      if (sessionStorage.getItem("isLishiEmail") != "" && sessionStorage.getItem("isLishiEmail")!= null && sessionStorage.getItem("isLishiEmail")!= 'null') {
          url = `${url}userEmail=${sessionStorage.getItem("isLishiEmail")}`
      }
      if (localStorage.getItem('phone') != "" && localStorage.getItem('phone')!= null && localStorage.getItem('phone')!= 'null') {
          url = `${url}&userPhone=${localStorage.getItem('phone')}`
      }
      this.$http.get(url).then((res) => {
        if (res.data.code == 0) {
          res.data.data.forEach((item) => {
            item.command = item.personalAccount ? 'self' : 'company'
            if(item.personalAccount){
              item.name = '个人'
            }
            // if(index < 5){
            //     this.hadJoinCompanyList.push(item)
            // }
          });
          this.hadJoinCompanyList = res.data.data;
          sessionStorage.setItem('companyList',JSON.stringify(res.data.data))
        }
      });
    },
    openPayVersion() {
      this.$router.push({ name: "payVersionView" })
    },
    openInviteFriendsPopup() {
      this.popoverVisible = false;
      this.$refs.inviteFriends.isInviteFriends = true;
    },
    // 退出登录
    logout() {
      this.$emit("loginOut");
    },
    //邀请好友
    invitePopup() {
      this.popoverVisible = false;
      this.$refs.inviteFriends.isInviteFriends = true;
    },
    // 意见反馈, 打开 toxiaochao 页面
    toxiaochao() {
      let data = {
        "nickname": this.userInfo.name,
        "avatar": (this.avtImageUrl === "" || this.avtImageUrl === "null") ? '' : this.avtImageUrl,
        "openid": localStorage.getItem("_uid")
      };
      // productId = 527647; // 把1221数字换成你的产品ID，否则会不成功
      // Tucao.request(productId, data);
      // window.open("https://support.qq.com/product/527647");
      let urlParam = `openid=${data.openid}&avatar=${data.avatar}&nickname=${data.nickname}`
      let url = `${location.origin}/lightning-web/askbot.html?${encodeURIComponent(urlParam)}`;
      window.open(url);
    },
    // 修改用户头像 姓名
    saveCustomerInfo(type, val) {
      let userInfo = {};
      if (type === "avt") {
        userInfo.profilePhoto = val;
      }
      if (type === "realName") {
        if (val.trim() === "") {
          this.messageFn('warning', '用户名不能为空哦！');
          return false;
        } else {
          userInfo.realName = val;
        }
      }
      userInfo.updateUserAllAccount = true;
      this.$emit("changeUserInfo", userInfo, () => {
        if (type === "avt") {
          localStorage.setItem("profilePhoto", val);
          this.messageFn('success', '头像修改成功！');
        }
        if (type === "realName") {
          this.nameEditing = false;
          this.userInfo.name = val;
          // localStorage.setItem("realName", val);
          localStorage.setItem("knowledgeNickName", val);
          this.messageFn('success', '用户名修改成功！');
        }
      })
    },

    // 打开兑换码弹框
    toRedemptionCode() {
      this.popoverVisible = false;
      this.redemptionValue = '';
      this.redemptionVisable = true;
    },
    // 编辑用户姓名自动聚焦并选中
    nameEditingFocus(e) {
      e.target.select();
    },
    aliyunOssRequest(data) {
      let file = data.file;
      // ossConfig.bucket = "guoranopen-zjk";
      let res = multipartUpload(generateOssConfig("open"), file, (process) => {
        console.log(process);
      });
      return res;
    },
    /**
     * 上传头像
     * @method handleAvatarSuccess
     * @param {}
     * @return
     */
    handleAvatarSuccess(res, file) {
      console.log(183, res, file);
      // ossConfig.bucket = "guoranopen-zjk";
      this.avtImageUrl = URL.createObjectURL(file.raw);
      this.saveCustomerInfo("avt", ossFileUrl(generateOssConfig("open"), res.name));
    },
    beforeAvatarUpload(file) {
      const isSupportedFormat =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/jpeg";
      const isLte2M = file.size / 1024 / 1024 <= 5;

      if (!isSupportedFormat) {
        this.messageFn('error', '上传头像图片只能是 JPG 或 PNG 格式 或 JPEG 格式!');
      }
      if (!isLte2M) {
        this.messageFn('error', '上传头像图片大小不能超过 5MB!');
      }
      return isSupportedFormat && isLte2M;
    },
    handlerUnit(value){
      return value/1024/1024/1024>=1 ? (value/1024/1024/1024).toFixed(1) + 'GB' : (value/1024/1024).toFixed(1) + 'MB'
    },
    goBuy(){
      this.showMorePopups = true;
    },
    // 获取容量和使用次数信息
    async getVerInfo() {
      console.log(this.currentVersion);
      let param = {
        mainId: localStorage.getItem("_mainId"),
        userId: localStorage.getItem("_uid"),
        vipType:this.currentVersion.vipType,
        expire:this.currentVersion.expire,
        userSource:localStorage.getItem('accountSource')
      }
      await this.$http
          .post(requestUrl.upload.getCapacity)
          .then((res) => {
            if (res.data.code == 0) {
              this.updateCombinationInfo(res.data.data);

            } else {
              this.messageFn('error', res.data.message);
            }
          });
      this.$http
          .post(requestUrl.upload.getCount, param)
          .then((res1) => {
            this.updateCombinationInfo(res1.data);
            console.log(this.combinationInfo,'combinationInfo');
          });
    },
    //  查询用户会员信息、流量包、空间容
    selectUserBuyAbility(){
      this.$http.get(requestUrl.pay.selectUserBuyAbility).then((res) => {
        if (res.data.code == 0) {
          console.log('main');
          this.updateCurrentVersionInfo(res.data.data);
          this.getVerInfo();
        }
      })
    },
  },
  mounted() {
    this.isLishi = sessionStorage.getItem('isLishi') == 1;
    this.knowledgeUserName = localStorage.getItem('knowledgeUserName');
    this.mainId = localStorage.getItem('_mainId');
    this.updateNavList();
    if (sessionStorage.getItem('searchToIndex') || sessionStorage.getItem('aipptToIndex') || sessionStorage.getItem('myKnowledgeToIndex')){
      let indexView = this.navList.find(item => item.id === 'knowledge')
      this.handleSelect(indexView);
      sessionStorage.removeItem('searchToIndex')
      sessionStorage.removeItem('aipptToIndex')
      sessionStorage.removeItem('myKnowledgeToIndex')
    } else if (sessionStorage.getItem('versionToIndex')){
      this.activeNavId = 'enterpriseKnowledgeView'
      this.$router.push({ name: "knowledgeView", query: { enterpriseKnowledgeTo:true } })
      sessionStorage.removeItem('versionToIndex')
    }

    this.handlerSwitchCompany();
    if (this.$route.path === '/joinCompany' ){
      this.onlyShowUserIcon = true;
    }
    window.addEventListener("load", () => {
      this.$parent.getInviteInfo();
      // 监听页面强制刷新
      if (this.$route.path !== '/login' && this.$route.path !== '/auth' && this.$route.path !== '/index' && this.$route.path !== '/payVersion') {
        this.selectUserBuyAbility();
      }
    });
    this.isClient = sessionStorage.getItem('isClient')
    window.addEventListener('message', (e) => {
      let data = e.data;
      if (data.data == 'close_dropdown'){
        this.$refs.refSelect.hide();
      }
    });
  },
  watch:{
    $route:{
      handler(to, from){
        console.log(to, from,'$route');
        if (to.path === '/searchKnowledge' && (sessionStorage.getItem('createNewCompany') || sessionStorage.getItem('_switchCompany'))){
          this.userInfo.name = localStorage.getItem("realName");
          this.avtImageUrl = localStorage.getItem("profilePhoto") == "null" ? "https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/2023/03/18/11/48/878f878d-6343-4f48-ada7-86f05211910a/askdefaltleftavt.png" : localStorage.getItem("profilePhoto");
          this.accountSource = Number(localStorage.getItem('accountSource'));
          this.accountSourceName = localStorage.getItem('accountSourceName');
          this.knowledgeUserName = localStorage.getItem('knowledgeUserName');
          this.mainId = localStorage.getItem('_mainId');
          this.updateNavList();
          sessionStorage.removeItem('createNewCompany');
          sessionStorage.removeItem('_switchCompany')
        }
        this.onlyShowUserIcon = to.path === '/joinCompany' ? true : false;
        if (to.path === '/searchKnowledge'){
          this.activeNavId = 'bot'
        } else if (to.name === 'IndexView'){
          this.activeNavId = 'knowledge'
        } else if (to.name === 'enterpriseKnowledgeView'){
          this.activeNavId = 'enterpriseKnowledgeView'
        } else if (to.name === 'recycleBinView'){
          this.activeNavId = 'recycleBin'
        } else if (to.name === 'toDoList'){
          this.activeNavId = 'toDo'
        } else if (to.name === 'workOrderView'){
          this.activeNavId = 'order'
        } else if (to.name === 'TemplateView'){
          this.activeNavId = 'mode'
        }
      },
      immediate:true
    },

  }
};
</script>
<style lang="less">
// .el-dropdown-menu__item--divided {
//   bottom: 100px;
// }
// .app-header-company-list {
//   li:last-child {
//     position: absolute;
//     width: calc(100% - 42px);
//     bottom: 0;
//   }
// }
</style>

<style scoped lang="less">
.create-company-button{
  bottom: 90px !important;
}
.other-nav {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 160px;
  .other-nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 58px;
    margin: 5px;
    cursor: pointer;
    font-size: 12px;
    color: #606266;
    padding: 4px;
    border: solid 2px #F2F6FC;
    border-radius: 4px;
    &:hover {
      background: #e0e7ef; // 更新悬停背景颜色
      border-radius: 4px;
    }
    i {
      margin-bottom: 4px;
      font-size: 14px;
      color: #606266;
    }
  }
}
.ask-left{
  height: 100%;
  background: #f3f6fa;
  border-right: 1px solid #e8ebf7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background 0.3s;
  .side-bar {
    width: 64px;
    border-radius: 5px 0 0 5px;
    text-align: center;
    padding: 20px 0;
    height: calc(100% - 160px);
    background: #f3f6fa;
    .header-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        transition: transform 0.3s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .side-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      cursor: pointer;
      font-size: 12px;
      margin: 0 auto;
      color: #444444;
      padding: 4px;
      transition: background 0.2s, color 0.2s;
      &:hover {
        background: #e0e7ef; // 更新悬停背景颜色
        border-radius: 5px;
      }
      i {
        margin-bottom: 4px;
        font-size: 20px;
        color: #444444;
      }
      span{
        font-size: 11px;
      }
      .guoran-tongyichicun-write-29-jiqiren2 {
        font-size: 18px;
      }
    }

    .set-up{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 12px;
      color: #444444;
      &:hover {
        color: #366aff;
      }
    }
    .active-side-item {
      //background: #F0F4FF;
      //background-color: #FFFFFF;
      background-color: #e0e7ef;
      border-radius: 5px;
      //color: #366AFF;
      color: #FFFFFF;
      i, span {
        color: #366aff;
      }
    }
    .reset-height{
      height: auto!important;
    }
    .invite-item{
      margin-top: -8px;
      margin-left: 8px;
      margin-right: 8px;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #366aff;
      background-color: #eaefff;
      cursor:pointer;
      font-size: 10px;
    }
  }
  .aph-web-user-info{
    .switch{
      display: flex;
      flex-direction: column;
      font-size: 11px;
      color: #000000;
      cursor: pointer;
      padding: 4px;
      i{
        padding-bottom: 4px;
        font-size: 14px;
        cursor: pointer;
      }
      &:hover {
        background: #e0e7ef; // 更新悬停背景颜色
        border-radius: 5px;
      }
    }
  }
  .aph-web-user-avt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 10px;
    .avatar {
      cursor: pointer;
      //margin-top: 20px;
      transition: transform 0.3s;
      &:hover {
        transform: scale(1.1);
      }
    }

    .aph-web-user-avt-inner {
      display: block;
      width: 31px;
      height: 31px;
      line-height: 31px;
      background: #366aff;
      border-radius: 50%;
      color: white;
      font-size: 12px;
      text-align: center;
      font-weight: 700;
      cursor: pointer;
      transition: background 0.3s;

      &:hover {
        background: #2c5dbf;
      }
    }
  }
}

.aph-web-user-avt-pop {
  padding: 14px 0 0 0 !important;

  .awua-box {
    .awuab-top {
      display: flex;
      justify-content: flex-start;
      // border-bottom: solid 1px #E0E6F7;
      padding-bottom: 12px;
      align-items: center;

      .awuab-top-avt:hover {
        width: 31px;

        .awuab-top-avt-default {
          .awuab-top-avt-default-hover {
            display: block;
          }
        }
      }

      .awuab-top-avt {
        flex: none;
        //margin-left: 12px;
        width: 31px;
        height: 31px;
        border-radius: 50%;
        overflow: hidden;

        .awuab-top-avt-default {
          position: relative;
          line-height: 31px;
          width: 31px;
          text-align: center;

          .awuab-top-avt-default-name {
            background: #366aff;
            line-height: 31px;
            color: white;
            font-size: 13px;
            font-weight: 700;
            text-align: center;
          }

          .awuab-top-avt-default-name-image {
            line-height: 31px;
            color: white;
            font-size: 13px;
            font-weight: 700;
            text-align: center;
          }

          .awuab-top-avt-default-hover {
            display: none;
            position: absolute;
            width: 31px;
            height: 31px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.3);
            top: 0;
            left: 0;
            color: white;
            margin: 0 auto;
          }
        }
      }

      .awuab-top-name {
        flex: auto;
        margin-left: 6px;
        display: flex;
        align-items: center;

        .awuab-top-name-text {
          margin-left: 6px;
          font-size: 14px;
          display: flex;
          align-items: center;
          margin-right: 12px;

          i {
            font-size: 14px;
            cursor: pointer;
            margin-left: 6px;
          }

          i:hover {
            color: #366aff;
            font-weight: 600;
          }
        }

        .awuab-top-name-edit {
          margin-right: 12PX;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: 12px;

          .el-input {
            margin-right: 8px;
          }

          .atne-delete {
            font-size: 18px;
            cursor: pointer;
            margin-left: 6px;
            color: #FF8089;
          }

          .atne-save {
            font-size: 18px;
            cursor: pointer;
            color: #759FFF;
          }
        }
      }
    }

    .awuab-handle-box {
      // margin-bottom: 12px;

      .el-divider{
        margin: 4px 0;
      }
      .ahb-cell-active,
      .ahb-cell:hover {
        background: #759FFF;
        color: white;
      }

      .ahb-cell {
        color: #404040;
        height: 38px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        cursor: pointer;

        .ahb-cell-icon {
          margin-right: 6px;
        }

        .ahb-cell-text {}
      }
    }

    .ask-lw-combination {
      // height: 280px;
      width: 200px;
      padding: 0 12px;
      //position:fixed;
      //left: 10px;
      //bottom: 0;

      .alc-item {
        width: 100%;
        padding-bottom: 8px;
        margin-top: 8px;
        border-bottom: 1px dotted #E2E6EE;

        .alc-item-title {
          display: flex;
          justify-content: space-between;
          font-weight: 500;

          .alc-item-title-pre {
            text-align: left;
            flex: auto;
            display: flex;
            align-items: center;

            .aitp-name {
              margin-right: 3px;
            }

            .guoran-tongyichicun-yiguoqi {
              font-size: 14px;
              color: #B0C4FF;
            }
          }

          .alc-item-title-after {
            text-align: right;
            cursor: pointer;
            // width: 40px;
            flex: none;
            color: #366aff;
          }
        }

        .alc-item-value {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .alc-item-value-surplus-times{
            width: 100%;
            color: #366AFF;
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .times{
              font-size: 12px;
            }
            .alc-item-title-after{
              font-weight: 500;
              cursor: pointer;
              font-size: 14px;
            }
            .surplus-times{
              color:#999999;
              .times{
                color: #366AFF;
              }
            }
          }

          .alc-item-value-pre {
            width: 90px;
            max-width: 90px;
            height: 3px;
            background: #E8EEFF;
            border-radius: 2px;

            .alc-item-value-pre-innner {
              max-width: 90px;
              display: block;
              height: 3px;
              // width: 50px;
              background: #366aff;
              border-radius: 2px;
            }
          }

          .alc-item-value-after {
            margin-top: 2px;
            font-size: 12px;

            .aiva-user {
              color: #A9B3C6;
            }

            .aiva-all {
              color: #366aff;
            }
          }

        }

        .alc-item-red-tips{
          font-size: 12px;
          color: #FE5965;
          text-align: left;
          margin-top: 2px;
        }
        &:last-child{
          padding-bottom: 26px;
          border: none;
        }
      }
      .company-tips{
        font-size: 12px;
        color: #999999;
        text-align: left;
        span{
          cursor: pointer;
          color: #366AFF;
        }
      }
    }
  }
}
.set-up-container{
  .set-item{
    display: flex;
    align-items: center;
    cursor: pointer;
    i{
      margin-right: 6px;
    }
  }
}
.active-company{
  background-color: #eaefff;
}
</style>
